<template>

        <v-navigation-drawer
          class="primary--text"
          v-model="drawer"
          permanent
        >

          <v-divider></v-divider>
          <v-list>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.route"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
</template>
<script>
  import { mapActions } from "vuex";
  import menuItems from "@/services/menu";

  export default {
    data () {
      return {
        drawer: true,
        items: menuItems,
      }
    },
    methods: {
      ...mapActions(['signOut']),
      logout() {
        this.signOut()
        this.$router.push('/')
      }
    }
  }
</script>
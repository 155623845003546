<template>
    <v-toolbar
       color="white"
    >
      <v-toolbar-title class="d-flex align-center">Dashboard <v-icon small color="primary" class="mx-2"> mdi-chevron-right </v-icon> {{ $route.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
       <v-avatar
              color="brown"
              size="48"
              class="mr-2"
            >
            <img
                    :src="user.profile_photo"
                >
       </v-avatar>
       <div class="mr-2">
            <v-container>
                <v-row>
                    <h3 class="primary--text">{{ user.name }}</h3>
                </v-row>
                <v-row>
                    <span class="text-weight-light text-caption"> Admin </span>
                </v-row>
            </v-container>
       </div>
    <v-menu offset-y nudge-bottom="25">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs"
          v-on="on">
           mdi-chevron-down
       </v-icon>
      </template>
      <v-list>
        <v-list-item @click="$router.push('profile')">
           <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>
         <v-list-item @click="logout">
           <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-toolbar>
</template>
<script>
  import { mapActions } from "vuex";
  export default {
    data: () => ({
      user: {
      name: '',
      profile_photo: null,
      phone: '',
      user_email: '',
      },
    }),
    created() {
      this.getProfile()
    },
    methods: {
      ...mapActions(['signOut']),
      logout() {
        this.signOut()
        this.$router.push('/')
      },
      getProfile(){
          this.$Axios.get("get-profile")
          .then(({data}) => {
            this.user = data["Success"];
          }) 
      }
    }
  }
</script>
<template>
  <div>
     <the-bar />
     
     <v-content>
       <v-row no-gutters>
         <v-col cols="2">
           <the-drawer />
         </v-col>
         <v-col>
           <v-container>
              <router-view/>
           </v-container>
         </v-col>
       </v-row>
     </v-content>
  </div>
</template>

<script>
  import TheDrawer from '../components/TheDrawer'
  import TheBar from '../components/TheBar'

  export default {
    name: 'Home',

    components: {
       TheDrawer,
       TheBar
    },
  }
</script>
